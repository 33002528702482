<template>
  <div>
    <header id="header" class="fixed-top header-transparent">
      <div class="container d-flex align-items-center justify-content-between position-relative">

        <div class="logo">
          <!--<h1 class="text-light"><a href="index.html"><span>Squadfree</span></a></h1>-->
          <!-- Uncomment below if you prefer to use an image logo -->
          <a href="index.html"><img src="assets/img/logo-420x100.png" alt="" class="img-fluid"></a>
        </div>

        <nav id="navbar" class="navbar">
          <ul>
            <li><a class="nav-link scrollto active" href="#hero">ACCUEIL</a></li>
            <li><a class="nav-link scrollto" href="#about">A PROPOS</a></li>
            <li><a class="nav-link scrollto" href="#services">S'ENREGISTRER</a></li>
            <li><a class="nav-link scrollto" href="https://payegen.bj/admin/verification">VERIFICATION</a></li>
            <li><a class="nav-link scrollto" href="https://payegen.bj/admin/connexion">CONNEXION</a></li>

            <li><a class="nav-link scrollto" href="#contact">CONTACT</a></li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->

      </div>
    </header><!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <section id="hero">
      <div class="hero-container" data-aos="fade-up">
        <h1>PayeGEN</h1>
        <h2>votre générateur de fiches de paie sécurisées !</h2>
        <a href="#about" class="btn-get-started scrollto"><i class="bx bx-chevrons-down"></i></a>
      </div>
    </section><!-- End Hero -->

    <main id="main">

      <!-- ======= About Section ======= -->
      <section id="about" class="about">
        <div class="container">

          <div class="row no-gutters">
            <div class="content col-xl-5 d-flex align-items-stretch" data-aos="fade-up">
              <div class="content">
                <h3>PayeGEN, la plateforme d'émission des fiches de paie sécurisées</h3>
                <p>
                  PayeGEN se veut être le « complément sécurité » à tout logiciel de paye en ce sens qu’il apporte la
                  fonction de sécurisation des informations figurant sur le bulletin de paie.
                </p>
                <p>
                  <img src="assets/img/ficpaye.png" alt="">
                </p>
                <a href="https://payegen.bj/admin/verification" class="about-btn">Vérifier une fiche de paie <i
                    class="bx bx-chevron-right"></i></a>
              </div>
            </div>
            <div class="col-xl-7 d-flex align-items-stretch">
              <div class="icon-boxes d-flex flex-column justify-content-center">
                <div class="row">
                  <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                    <i class="bx bx-hash"></i>
                    <h4>Code PayeGEN</h4>
                    <p>Signature numérique unique sur chaque fiche de paie résultant d'un encodage sur 24 caractères</p>
                  </div>
                  <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                    <i class="bx bx-cube-alt"></i>
                    <h4>Numéro Unique PayeGEN</h4>
                    <p>Chaque organisation dispose d'un numéro unique d'identification PayeGEN (NUP) obtenue lors de
                      l'enregistrement</p>
                  </div>
                  <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                    <i class="bx bx-qr"></i>
                    <h4>Génération de QrCode</h4>
                    <p>Elément de contrôle de l'authenticité et d'intégrité des informations du bulletin de paie</p>
                  </div>
                  <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                    <i class="bx bx-import"></i>
                    <h4>Intégrateur de données</h4>
                    <p>Module multi-formats d'intégration des données de paie : CSV, XML, JSON (Api Restful)</p>
                  </div>
                  <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                    <i class="bx bx-receipt"></i>
                    <h4>Edition personnalisée</h4>
                    <p>Adaptation et personnalisation de modèles de bulletins de paie </p>
                  </div>
                  <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="600">
                    <i class="bx bx-data"></i>
                    <h4>Dématérialisation</h4>
                    <p>Edition PDF protégé et transmission du bulletin de paie par email à chaque employé</p>
                  </div>
                </div>
              </div><!-- End .content-->
            </div>
          </div>

        </div>
      </section><!-- End About Section -->

      <!-- ======= Services Section ======= -->
      <section id="services" class="services">
        <div class="container">

          <div class="section-title" data-aos="fade-in" data-aos-delay="100">
            <h2>Inscription sur PayeGEN</h2>
            <p>Enregistrez dès à présent votre entreprise sur PayeGEN et nos équipes vous accompagnerons à mettre en place
              le plateforme de sécurisation des fiches de paie délivrées à vos employés </p><br>
            <p><a href="https://payegen.bj/admin/employeur" class="services-btn"> Obtenez votre Numéro Unique PayeGEN (NUP)<i
                  class="bx bx-chevron-right"></i></a></p>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up">
                <div class="icon"><i class="bx bxl-dribbble"></i></div>
                <h4 class="title"><a href="">Modernité</a></h4>
                <p class="description">Solution novatrice d'édition de fiches de paie</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div class="icon"><i class="bx bx-lock"></i></div>
                <h4 class="title"><a href="">Securité</a></h4>
                <p class="description">Signature numérique unique sécurisée à partir du Code PayeGEN. Bulletin de paie
                  électronique pdf protégé</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div class="icon"><i class="bx bx-shield"></i></div>
                <h4 class="title"><a href="">Intégrité</a></h4>
                <p class="description">Vérification en ligne à partir du NUP et du Code PayeGEN. App mobile de contrôle du
                  QrCode</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
                <div class="icon"><i class="bx bx-money"></i></div>
                <h4 class="title"><a href="">Economique</a></h4>
                <p class="description">Gain de temps des équipes RH, solution économique et respectueuse de
                  l'environnement</p>
              </div>
            </div>

          </div>

        </div>
      </section><!-- End Services Section -->

      <!-- ======= Counts Section ======= -->
      <section id="counts" class="counts  section-bg">
        <div class="container">

          <div class="row no-gutters">

            <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
              <div class="count-box">
                <i class="bi bi-emoji-smile"></i>
                <span data-purecounter-start="0" data-purecounter-end="4" data-purecounter-duration="1"
                  class="purecounter"></span>
                <p><strong>Entreprises</strong> disposent déjà d'un NUP</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
              <div class="count-box">
                <i class="bi bi-people"></i>
                <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1"
                  class="purecounter"></span>
                <p><strong>Employés</strong> sont pris en comptes</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
              <div class="count-box">
                <i class="bi bi-headset"></i>
                <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1"
                  class="purecounter"></span>
                <p><strong>Fiches de paie</strong> sécurisées par PayeGEN</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
              <div class="count-box">
                <i class="bi bi-emoji-smile"></i>
                <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1"
                  class="purecounter"></span>
                <p><strong>Vérifications</strong> effectuées à en ligne</p>
              </div>
            </div>

          </div>

        </div>
      </section>

      <section id="contact" class="contact section-bg">
        <div class="container" data-aos="fade-up">

          <div class="section-title">
            <h2>Contact</h2>
            <p></p>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="info-box mb-4">
                <i class="bx bx-map"></i>
                <h3>Adresse géographique</h3>
                <p>Porte N°56, Rue face Direction FIFA, Menontin, Cotonou (BENIN)</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="info-box  mb-4">
                <i class="bx bx-envelope"></i>
                <h3>Email </h3>
                <p>contact@payegen.bj</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="info-box  mb-4">
                <i class="bx bx-phone-call"></i>
                <h3>Téléphone</h3>
                <p>+229 95338469</p>
              </div>
            </div>

          </div>

          <div class="row">

            <div class="col-lg-6 ">
              <iframe class="mb-4 mb-lg-0"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
                frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen></iframe>
            </div>

            <div class="col-lg-6">
              <form action="forms/contact.php" method="post" role="form" class="php-email-form">
                <div class="row">
                  <div class="col-md-6 form-group">
                    <input type="text" name="name" class="form-control" id="name" placeholder="Votre Nom" required>
                  </div>
                  <div class="col-md-6 form-group mt-3 mt-md-0">
                    <input type="email" class="form-control" name="email" id="email" placeholder="Votre Email" required>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <input type="text" class="form-control" name="subject" id="subject" placeholder="Objet" required>
                </div>
                <div class="form-group mt-3">
                  <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                </div>
                <div class="my-3">
                  <div class="loading">Chargement</div>
                  <div class="error-message"></div>
                  <div class="sent-message">Votre message a été envoyé. Merci!</div>
                </div>
                <div class="text-center"><button type="submit">Envoyer</button></div>
              </form>
            </div>

          </div>

        </div>
      </section><!-- End Contact Section -->

    </main><!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer id="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row">

            <div class="col-lg-4 col-md-6">
              <div class="footer-info">
                <h3>dotBIT</h3>
                <p class="pb-3"><em>Le rond point IT du Benin.</em></p>
                <p>
                  Menontin <br>
                  Cotonou, BENIN<br><br>
                  <strong>Phone:</strong> +229 95338469<br>
                  <strong>Email:</strong> info@dotb-it.com<br>
                </p>
                <div class="social-links mt-3">
                  <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                  <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                  <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                  <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                  <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                </div>
              </div>
            </div>

            <div class="col-lg-2 col-md-6 footer-links">
              <h4>Liens Utiles</h4>
              <ul>
                <li><i class="bx bx-chevron-right"></i> <a href="#">Accueil</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href="#">A propos</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href="https://payegen.bj/admin/employeur">S'enregistrer</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href="https://payegen.bj/admin/verification">Vérification</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href="https://payegen.bj/admin/connexion">Connexion</a></li>
              </ul>
            </div>

            <!-- <div class="col-lg-2 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
            </ul>
          </div> -->

            <!-- <div class="col-lg-4 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
            <form action="" method="post">
              <input type="email" name="email"><input type="submit" value="Subscribe">
            </form>

          </div> -->

          </div>
        </div>
      </div>

      <div class="container">
        <div class="copyright">
          &copy; Copyright <strong><span>dotbIT</span></strong>. All Rights Reserved
        </div>
        <div class="credits">
          <!-- All the links in the footer should remain intact. -->
          <!-- You can delete the links only if you purchased the pro version. -->
          <!-- Licensing information: https://bootstrapmade.com/license/ -->
          <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/squadfree-free-bootstrap-template-creative/ -->
          <!-- Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> -->
        </div>
      </div>
    </footer><!-- End Footer -->
    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
        class="bi bi-arrow-up-short"></i></a>
  </div>
</template>

<script>


export default {
  name: "HomeView",
  components: {

  },


};
</script>
